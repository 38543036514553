<template>
    <section>
        <span v-if="!usersToShow.length && !searchUsers" @click="searchUsers = true">
            Not Assigned
        </span>
        <div class="search" v-else-if="searchUsers" v-click-outside="quitSearch">
            <input v-model="searchKey" type="text" placeholder="Search...">
            <div class="users" :class="{'add-user': loading}" v-if="usersToPresent.length">
                <template v-for="(user, i) in usersToPresent" :key="i">
                    <div class="user" :class="{'no-img': !user.user.avatar}" @click="assignUser(user.user)">
                        <figure>
                            <img v-if="user.user.avatar" :src="imgURL + user.user.avatar" alt="">
                            <span v-else>{{user.user.name.charAt(0)}}</span>
                        </figure>
                        <span>{{user.user.name}}</span>
                    </div>
                </template>
            </div>
            
        </div>
        <div class="users-assigned" @click="searchUsers = true" v-else>
            <template v-for="(user, i) in usersToShow.slice(0, 4)" :key="i">
                <div class="user" :title="user.name" :class="{'no-img': !user.avatar}">
                    <figure>
                        <img v-if="user.avatar" :src="imgURL + user.avatar" alt="">
                        <span v-else>{{user.name.charAt(0)}}</span>
                    </figure>
                </div>
            </template>
            <div v-if="usersToShow.length > 4" class="user more">
                <figure>
                    <span>+</span>
                </figure>
            </div>
        </div>
    </section>
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance';

export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
        id: {
            type: Number,
            default: () => 0
        }
    },

    data() {
        return {
            searchUsers: false,
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
            searchKey: '',
            timeout: null,
            usersToPresent: [],
            imgURL: process.env.VUE_APP_DO_SPACES,
            newUsers: [],
            loading: false
        }
    },

    computed: {
        usersToShow() {
            return this.data.concat(this.newUsers)
        }
    },

    watch: {
        searchKey(val) {
            if (!val.length) {
                this.usersToPresent = []
                return
            }

            clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.fetchUsers();
            }, 1000);
        }
    },

    methods: {
        async fetchUsers() {
            const response = await axiosInstance.get(`get-users?org_id=${this.org.id}&searchKey=${this.searchKey}&type=users&limit=100`)
            if (response.status !== 200) {
                this.usersToPresent = []
                return
            }

            const assignedIds = this.usersToShow.map(el => el.id)

            this.usersToPresent = response.data.data.data.filter(el => !assignedIds.includes(el.user.id))
        },

        quitSearch() {
            this.searchUsers = false
            this.searchKey = ''
        },

        async assignUser(user) {
            this.loading = true

            const params = {
                id_ticket: this.id,
                id_organization: this.org.id,
                users: [{ id_user: user.id }],
            }

            const response = await axiosInstance.post('user-assign-ticket', params)
            this.loading = false

            if (response.status !== 200) {
                return
            }

            if (!this.data.length) {
                this.emitter.emit('reload-tickets')
                return
            }
            
            this.newUsers.push(user)
            this.quitSearch()
        }
    }
}
</script>

<style lang="scss" scoped>
section {
    width: 100%;
    span {
        width: 85%;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    

    .search {
        width: 100%;
        position: relative;

        input {
            width: 80%;
            border-bottom: solid 1px black;
            transition: 0.2s;
            padding: 10px;

            &:focus {
                border-bottom: solid 1px var(--primary-color);
            }
        }

        .users {
            position: absolute;
            width: 80%;
            background: white;
            overflow: auto;
            height: 150px;
            border: solid 1px;
            border-color: transparent var(--primary-color) var(--primary-color) var(--primary-color);
            border-radius: 2px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            display: flex;
            flex-direction: column;

            &.add-user {
                pointer-events: none;
                cursor: not-allowed;
            }

            .user {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                padding: 10px;
                transition: 0.2s;

                &.no-img {
                    figure {
                        border-color: transparent;
                    }
                }

                &:hover {
                    background: rgb(235, 235, 235);
                }

                figure {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    border: solid 1px var(--primary-color);

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    span {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                        background: var(--primary-color);
                    }
                }

                span {
                    width: calc(100% - 30px - 0.5rem);
                    font-weight: 400;
                }
            }
        }
    }

    .users-assigned {
        display: flex;

        .user {
            &.no-img {
                figure {
                    border-color: transparent;
                }
            }

            &.more {
                figure {
                    span {
                        background: #f2f5f8;
                        color: black;
                    }
                }
            }

            &:not(:first-of-type) {
                margin-left: -15px;
            }

            figure {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                overflow: hidden;
                border: solid 1px var(--primary-color);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                span {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    background: var(--primary-color);
                }
            }
        }
    }
}
</style>