<template>
    <div class="multiple">
        <template v-if="data.length">
            <template v-for="(item,index) in data" :key="index">
                <span v-if="index < 3">{{item.name}}</span>
                <span v-else-if="index === 3" class="more">+{{data.length - 3}}</span>
            </template>
        </template>
        <template v-else>undefined</template>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array
        }
    },
}
</script>

<style lang="scss" scoped>
.multiple {
    width: 100%;
    display: flex;
    flex-direction: column;
    span {
        font-size: 0.8rem;
    }

    .more {
        font-size: 0.7rem;
    }
}
</style>