<template>
    <div class="icons" ref="usr-avt">
        <template v-for="(user,index) in data" :key="index">
            <template v-if="index < 4">
                <div class="user-avatar" :class="{more: index === 3}" :title="user.name">
                    <template v-if="index < 3">
                        <img v-if="user.avatar" :src="user.avatar" alt="">
                        <span v-else>{{user.name.charAt(0)}}</span>
                    </template>
                    <template v-if="index === 3">
                        <span class="more">+{{data.length - 3}}</span>
                    </template>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array
        }
    }
}
</script>

<style lang="scss" scoped>
.icons {
    display: flex;
    align-items: center;
    gap: 10px;
    .user-avatar{
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: white;
        background: var(--primary-color);
        overflow: hidden;
        border: solid 2px #F2F5F8;
        margin-left: -25px;

        &.more {
            color: black;
            background: #F2F5F8;
            font-size: 0.7rem;
            margin-left: -20px;
        }

        &:first-of-type {
            margin-left: unset;
        }

        img {
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
    }
}
</style>