<template>
    <section v-click-outside="handleOutside">
        <div class="active" :class="priorityToShow.name" @click="showPriorities = !showPriorities">
            <div class="data">
                <figure>
                    <img :src="priorityToShow.icon" :alt="`priority-${priorityToShow.name}`">
                </figure>
                <span :class="priorityToShow.name">{{priorityToShow.name}}</span>
            </div>
            <span class="arrow" :class="{reverse: showPriorities}" />
        </div>
        <div class="options" v-if="showPriorities">
            <template v-for="(priority, i) in filteredOptions" :key="i">
                <div class="priority" @click="selectPriority(priority.id)">
                    <figure>
                        <img :src="priority.icon" :alt="`priority-${priority.name}`">
                    </figure>
                    <span :class="priority.name">{{priority.name}}</span>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance';

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        id: {
            type: Number,
            default: () => 0
        }
    },

    data() {
        return {
            priorities: [
                {
                    id: 1,
                    icon: "/assets/images/low_flag.svg",
                    name: "low",
                },
                {
                    id: 2,
                    icon: "/assets/images/medium_flag.svg",
                    name: "medium",
                },
                {
                    id: 3,
                    icon: "/assets/images/high_flag.svg",
                    name: "high",
                },
            ],
            showPriorities: false,
            selectedPriority: this.data.priority_details.id

        }
    },

    computed: {
        priorityToShow() {
            return this.priorities.find(el => el.id === this.selectedPriority)
        },

        filteredOptions() {
            return this.priorities.filter(el => el.id !== this.selectedPriority)
        }
    },

    methods: {
        handleOutside() {
            this.showPriorities = false
        },

        async selectPriority(id) {
            this.selectedPriority = id
            this.showPriorities = false

            const data = {
                id_ticket: this.id,
                status: id
            }

            await axiosInstance.post(`ticket-priority-change`, data);
        
        }
    }
}
</script>

<style lang="scss" scoped>
section {
    width: 100%;
    position: relative;

    .active {
        display: flex;
        width: 100%;
        max-width: 200px;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 10px 0;

        &:hover {
            &::after {
                height: 2px;
            }
            &.low {
                &::after {
                    background: #cad6e2;
                }
            }

            &.medium {
                &::after {
                    background: #fb9a4b;
                }
            }

            &.high {
                &::after {
                    background: #ed0423;
                }
            }
        }

        


        &::after {
            content: '';
            width: 100%;
            height: 1px;
            background: #f2f5f8;
            position: absolute;
            bottom: 0px;
            transition: 0.2s;
        }

        .data {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            width: 80%;

            span {
                text-transform: capitalize;

                &.low {
                    color: #cad6e2;
                }

                &.medium {
                    color: #fb9a4b;
                }

                &.high {
                    color: #ed0423;
                }
            }
        }

        .arrow {
            width: 8px;
            height: 8px;
            border: solid 1px;
            border-color: black black transparent transparent;
            transform: rotate(135deg);
            transition: 0.2s;

            &.reverse {
                transform: rotate(-45deg);
            }
        }
    }

    span {
        width: 85%;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .options {
        position: absolute;
        left: 0;
        background: white;
        width: 100%;
        max-width: 200px;
        z-index: 10;
        border: solid 1px;
        border-color: transparent var(--primary-color) var(--primary-color) var(--primary-color);
        border-radius: 2px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

        .priority {
            padding: 10px;
            display: flex;
            gap: 0.5rem;
            border-bottom: solid 1px #f2f5f8;
            transition: 0.2s;

            &:last-of-type {
                border: none;
            }

            &:hover {
                background: rgb(235, 235, 235);
            }
        }
    }
}

</style>