<template>
    <div class="item-row">
        <template v-for="(item, i) in data" :key="i">
            <span :class="{light: item.light}">{{item.text}}</span>
        </template>
        
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array
        }
    }
}
</script>

<style lang="scss" scoped>
.item-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    span {
        width: 85%;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;

        &.light {
            font-weight: 500;
            font-size: 0.7rem;
            padding: 4px 0;
        }
    }
}

</style>