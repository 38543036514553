<template>
    <section class="users-list">
        <div class="table-head">
            <div class="main-items" :class="{check: hasCheck && canCreate, 'no-check': !hasCheck || !canCreate}">
                <span class="check" v-if="hasCheck && canCreate">
                    <input
                        aria-label="select all devices"
                        type="checkbox"
                        ref="mainCheck"
                        @change="checkedAll"
                    />
                </span>
                <template v-for="item in headItems" :key="item">
                    <span :style="{width: spanWidth}">{{item}}</span>
                </template>
                <span v-if="hasOptions && canCreate" class="options"></span>
            </div>
        </div>
        <template v-if="entries.length && loaded">
            <template v-for="(entry, index) in entries" :key="index">
                <Item :item="entry" :is-ticket-list="isTicketList" :allChecked="isChecked" :canEdit="canCreate" :width="spanWidth" :hasCheck="hasCheck" @alert="handleAlert" @check="handleCheck" @optionSel="handleOption" />
            </template>
        </template>
        <template v-else-if="!entries.length && (filter.length || search_key.length) && loaded">
            <span class="empty">
                There are currently no {{title.replace('y', 'ie')}}s for your search.
            </span>
        </template>
        <template v-else-if="!entries.length && loaded">
            <span class="empty">
                There are currently no {{title.replace(/y$/, 'ie')}}s created.
            </span>
        </template>
        <template v-else>
            <div class="loading">
                <span class="loader"></span>
            </div>
        </template>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_USER_PROFILE_GETTER } from '@/store/storeconstants';
import Item from './Item.vue'
export default {
    props: {
        title: {
            type: String
        },
        headItems: {
            type: Array,
            required: false,
            default: () => []
        },
        canCreate: {
            type: Boolean,
            required: false,
            default: () => false
        },
        entries: {
            type: Array,
            required: true,
            default: () => []
        },
        hasCheck: {
            type: Boolean,
            required: false,
            default: () => false
        },
        loaded: {
            type: Boolean,
            required: false,
            default: () => false
        },
        editOptions: {
            type: Array,
            required: false,
            default: () => []
        },
        isTicketList: {
            type: Boolean,
            default: () => false
        },
        search_key: {
            type: String,
            required: false,
            default: () => ''
        },
        filter: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data() {
        return {
            checked: [],
            isChecked: false,
        }
    },

    emits: ["optionSel", 'checks'],

    inject: ['hasOptions'],

    components: {
        Item
    },

    watch: {
        isChecked(val) {
            if (val) this.checked.push(...this.entries.map(el => el[0].id))
            else this.checked = []
            this.$emit('checks', this.checked)
        },
    },

    computed: {
        ...mapGetters('account', {
            getUserProfile: GET_USER_PROFILE_GETTER,
        }),

        spanWidth() {
            return `${93 / this.headItems.length}%`
        }
    },

    methods: {
        handleCheck(data) {
            if (!data[1]) {
                this.$refs.mainCheck.checked = false
                const index = this.checked.indexOf(data[0])
                this.checked.splice(index, 1)
            }
            else{
                this.checked.push(data[0])
            }

            this.$emit('checks', this.checked)
        },

        handleAlert(data) {
            this.emitter.emit('alert', data)
        },

        checkedAll(e) {
            this.isChecked = e.target.checked
        },
        
        handleOption(data) {
            this.$emit('optionSel', data)
        }
    }
}
</script>

<style lang="scss" scoped>
.users-list {
    height: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    .table-head {
      position: sticky;
      top: 0;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 10px 25px;
      box-shadow: 0px 1px 6px #CAD6E280;
      border-bottom: solid 1px #F2F5F8;
      background: white;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      z-index: 1;
      .main-items {
        width: 100%;
        display: flex;

        &.check {
            span {
                &:nth-child(2) {
                    @media only screen and (max-width: 768px) {
                        width: 100% !important;
                    }
                }
                &:not(:nth-child(2)) {
                    @media only screen and (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }

        &.no-check {
            span {
                &:nth-child(1) {
                    @media only screen and (max-width: 768px) {
                        width: 100% !important;
                    }
                }
                &:not(:nth-child(1)) {
                    @media only screen and (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }

        span {
            display: flex;
            font-size: 0.8rem;
            font-weight: 500;
            &.check {
                width: 5%;
                justify-content: flex-start;
                margin: 0 0;
                @media only screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
        .options {
            width: 3%;
        }
      }
    }
  }
</style>