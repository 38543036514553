<template>
    <div v-if="entries.length" class="pagination" ref="pagination">
        <!-- <span>Showing <b>{{ currentPage === 1 ? 1 : currentPage - 1 + '1'}}-{{ currentTotal }}</b> from <b>{{total}}</b></span> -->
        <div class="block" @click="$emit('goToFirst')">
            <span class="arrow left medium" />
            <span class="arrow left medium" />
        </div>
        <div class="block" @click="handlePage('left')">
            <span class="arrow left" />
        </div>
        <input type="text" class="current" v-model="page" @keyup.enter="handleCustomPage"/>
        <div class="block" @click="handlePage('right')">
            <span class="arrow right" />
        </div>
        <div class="block" @click="$emit('goToLast')">
            <span class="arrow right medium" />
            <span class="arrow right medium" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        entries: {
            type: Array
        },
        currentPage: {
            type: Number
        },
        total: {
            type: Number
        },
        perPage: {
            type: Number
        },
        pages: {
            type: Number
        }
    },

    data() {
        return {
            page: 1
        }
    },

    computed: {
        currentTotal() {
            return this.total > 10 && (this.perPage * this.currentPage < this.total) ? (this.perPage * this.currentPage) : this.total
        },
    },

    watch: {
        currentPage(val) {
            this.page = val
        },
    },

    created() {
        this.page = this.currentPage
    },

    methods: {
        handlePage(to) {
            switch (to) {
                case 'left':
                    if (this.currentPage > 1)  this.$emit('changePage', 'less')
                break;
                case 'right':
                    if (this.currentPage < this.pages)  this.$emit('changePage', 'more')
                break;
            }
        },

        handleCustomPage() {
            if (this.page > this.pages) {
                this.$emit('goToPage', this.pages)
                return
            }

            if (this.page < 1) {
                this.$emit('goToPage', 1)
                return
            }
            
            this.$emit('goToPage', this.page)
        }
    }
}
</script>

<style lang="scss" scoped>
.pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 5px;
    margin-bottom: 10px;

    img{
      width: 8px;
      cursor: pointer;
    }

    .block {
        width: 15px;
        aspect-ratio: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .arrow {
            width: 8px;
            height: 8px;
            border: solid 1px;
            border-color: black black transparent transparent;

            &.left {
                transform: rotate(-135deg);
            }

            &.right {
                transform: rotate(45deg);
            }

            &.medium {
                width: 6px;
                height: 6px;
            }
        }
    }

    

    .current {
        padding: 5px 10px;
        background: white;
        border-radius: 2px;
        font-weight: 500;
        width: 4rem;
        display: flex;
        justify-content: center;
        text-align: center;
    }
}
</style>