<template>
    <span>
        {{data}}
    </span>
</template>

<script>
export default {
    props: {
        data: {
            type: String
        }
    }
}
</script>

<style lang="scss" scoped>
span {
    width: 85%;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>