<template>
    <div class="table-row" :class="{check: hasCheck && canEdit, 'no-check': !hasCheck || !canEdit, notAssigned: isTicketList && item[0].notAssigned, messages: item[0].messages, closed: isTicketList && item[0].closed }">
        <span class="item check" v-if="hasCheck && canEdit">
            <input
                aria-label="select all devices"
                type="checkbox"
                ref="mainCheck"
                :checked="allChecked ? true : false"
                @change="handleCheck"
            />
        </span>
        <template v-for="(entry, index) in item" :key="index">
            <div class="item" :style="{width: width}" @click="index === 0 ? handleOption('see') : ''">
                    <normal-template v-if="entry.template === 'normal'" :data="entry.data" />
                    <image-template v-else-if="entry.template === 'image'" :messages="item[0].messages || 0" :data="entry.data" />
                    <multiple-template v-else-if="entry.template === 'multiple'" :data="entry.data" />
                    <multiple-rows-template v-else-if="entry.template === 'multiple-rows'" :data="entry.data" />
                    <multiple-icons-template v-else-if="entry.template === 'multiple-icons'" :data="entry.data" />
                    <file-template v-else-if="entry.template === 'file'" :data="entry.data" />
                    <assign-template v-else-if="entry.template === 'assign'" :id="entry.id" :data="entry.data" />
                    <priority-template v-else-if="entry.template === 'priority'" :id="entry.id" :data="entry.data" />
                    <status-template v-else-if="entry.template === 'status'" :item="entry.item" :users="entry.users" :options="entry.options" :data="entry.data" />
            </div>
        </template>
        <div v-if="hasOptions && canEdit" class="options" @click="isEdit = !isEdit" v-click-outside="handleOutside">
            <img src="/assets/images/black_dots.svg" alt="">
            <options-modal v-if="isEdit" :options="edit" :noDel="false" @handleAction="handleOption" />
        </div>
    </div>
</template>

<script>
import ImageTemplate from './Templates/Image.vue'
import MultipleTemplate from './Templates/Multiple.vue'
import MultipleRowsTemplate from './Templates/Rows.vue'
import MultipleIconsTemplate from './Templates/MultipleIcons.vue'
import NormalTemplate from './Templates/Normal.vue'
import FileTemplate from './Templates/File.vue'
import AssignTemplate from './Templates/Assign.vue'
import PriorityTemplate from './Templates/Priority.vue'
import StatusTemplate from './Templates/Status.vue'
import OptionsModal from '../../Ui/General/Options.vue';
export default {
    props: {
        item: {
            type: Object,
        },
        allChecked: {
            type: Boolean
        },
        canEdit: {
            type: Boolean
        },
        width: {
            type: String
        },
        hasCheck: {
            type: Boolean
        },
        isTicketList: {
            type: Boolean,
            default: () => false
        },
    },

    data() {
        return {
            isEdit: false,
            action: []
        }
    },

    inject: ['edit', 'hasOptions'],

    components: {
        ImageTemplate,
        MultipleTemplate,
        MultipleIconsTemplate,
        MultipleRowsTemplate,
        NormalTemplate,
        OptionsModal,
        FileTemplate,
        PriorityTemplate,
        AssignTemplate,
        StatusTemplate
    },

    methods: {
        handleOption(data) {
            this.$emit('optionSel', [data, this.item[0].id])
        },

        handleOutside() {
            if(this.isEdit) this.isEdit = false
        },

        handleCheck(e) {
            const value = e.target.checked
            this.$emit('check', [this.item[0].id, value])
        },
    }
}
</script>

<style lang="scss" scoped>
.table-row {
    height: 100px;
    display: flex;
    padding: 0 25px;
    background: white;
    border-bottom: solid 1px #F2F5F8;
    cursor: pointer;

    &.notAssigned {
        background: rgba(0,115,255,0.0352941)
    }

    &.messages {
        background: rgba(235,224,216,0.7411764705882353);
    }

    &.closed {
        background: rgba(235,216,216,0.30196078431372547);
    }

    &.check {
        .item {
            &:nth-child(2) {
                @media only screen and (max-width: 768px) {
                    width: 100% !important;
                }
            }
            &:not(:nth-child(2)) {
                @media only screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
    }

    &.no-check {
        .item {
            &:nth-child(1) {
                @media only screen and (max-width: 768px) {
                    width: 100% !important;
                }
            }
            &:not(:nth-child(1)) {
                @media only screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
    }

    .item {
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 600;
        &.check {
            width: 5%;
            margin: 0 0;
            @media only screen and (max-width: 768px) {
                display: none;
            }
        }
    }

    .options {
        width: 3%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        user-select: none;
        .dropdown-options{
            top: 60%;
        }
    }
}
</style>