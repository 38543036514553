<template>
    <section v-click-outside="handleOutside">
        <div class="active" @click="showStatus = !showStatus">
            <div class="data">
                <span>{{statusToShow?.name}}</span>
            </div>
            <span class="arrow" :class="{reverse: showStatus}" />
        </div>
        <div class="options" v-if="showStatus">
            <template v-for="(status, i) in filteredOptions" :key="i">
                <div class="status" @click="selectStatus(status.id)">
                    <span :class="status.name">{{status.name}}</span>
                </div>
            </template>
        </div>
    </section>
    <unassign
      v-if="unassign"
      :ticket="item"
      @close="handleUnassign"
    />
    <assign
      v-if="assign"
      :ticket="item"
      :users="users"
      @close="handleAssign"
    />
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance';
import Assign from "@/views/remote_support/pages/Overview/Assign.vue";
import Unassign from "@/views/remote_support/pages/Overview/Unassign.vue";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        item: {
            type: Object,
            default: () => {}
        },
        options: {
            type: Array,
            default: () => []
        },
        users: {
            type: Array,
            default: () => []
        }
    },

    components: {
        Unassign,
        Assign
    },

    data() {
        return {
            showStatus: false,
            selectedStatus: this.data.status,
            assign: false,
            unassign: false,
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
            pendingStatus: undefined
        }
    },

    computed: {
        statusList() {
            return this.options.concat([
                {
                    id: 0,
                    name: "Choose a Status",
                },
            ])
        },

        statusToShow() {
            return this.statusList.find(el => el.id === this.selectedStatus)
        },

        filteredOptions() {
            return this.statusList.filter(el => el.id !== 0 &&  el.id !== this.selectedStatus)
        }
    },

    methods: {
        handleOutside() {
            this.showStatus = false
        },

        async handleUnassign(item) {
            if (!item) {
                this.pendingStatus = undefined
                this.unassign = false
                return
            }
            
            this.unassign = false
            this.selectedStatus = this.pendingStatus

            const params = {
                org_id: this.org.id,
                ticket_id: this.item.id,
                user_ids: this.item.assigned_users.map((el) => el.id),
            }

            await axiosInstance
                .post(`un-assign-ticket-from-all-users`, params)

            this.emitter.emit('reload-tickets')
            this.pendingStatus = undefined
        },

        async handleAssign(item) {
            if (!item) {
                this.pendingStatus = undefined
                this.assign = false
                return
            }

            this.assign = false
            this.selectedStatus = this.pendingStatus

            const params = {
                id_organization: this.org.id,
                id_ticket: this.item.id,
                users: item.map((el) => {
                    return { id_user: el.id };
                }),
            }

            await axiosInstance.post('user-assign-ticket', params)
            await axiosInstance.post('change-ticket-status', {
                ticket_id: this.item.id,
                status_id: this.pendingStatus
            })

            this.emitter.emit('reload-tickets')
            this.pendingStatus = undefined
        },

        async selectStatus(id) {
            this.showStatus = false

            if (this.selectedStatus < 2 && id < 4) {
                this.assign = true
                this.pendingStatus = id
                return
            }

            if (id === 1) {
                this.unassign = true
                this.pendingStatus = id
                return
            }
            
            this.selectedStatus = id

            await axiosInstance.post('change-ticket-status', {
                ticket_id: this.item.id,
                status_id: id,
            })
        },
    }
}
</script>

<style lang="scss" scoped>
section {
    width: 100%;
    position: relative;

    .active {
        display: flex;
        width: 100%;
        max-width: 200px;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 10px 0;

        &:hover {
            &::after {
                height: 2px;
            }
            &.low {
                &::after {
                    background: #cad6e2;
                }
            }

            &.medium {
                &::after {
                    background: #fb9a4b;
                }
            }

            &.high {
                &::after {
                    background: #ed0423;
                }
            }
        }

        


        &::after {
            content: '';
            width: 100%;
            height: 1px;
            background: #f2f5f8;
            position: absolute;
            bottom: 0px;
            transition: 0.2s;
        }

        .data {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            width: 80%;

            span {
                text-transform: capitalize;

                &.low {
                    color: #cad6e2;
                }

                &.medium {
                    color: #fb9a4b;
                }

                &.high {
                    color: #ed0423;
                }
            }
        }

        .arrow {
            width: 8px;
            height: 8px;
            border: solid 1px;
            border-color: black black transparent transparent;
            transform: rotate(135deg);
            transition: 0.2s;

            &.reverse {
                transform: rotate(-45deg);
            }
        }
    }

    span {
        width: 85%;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .options {
        position: absolute;
        left: 0;
        background: white;
        width: 100%;
        max-width: 200px;
        z-index: 10;
        border: solid 1px;
        border-color: transparent var(--primary-color) var(--primary-color) var(--primary-color);
        border-radius: 2px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

        .status {
            padding: 10px;
            display: flex;
            gap: 0.5rem;
            border-bottom: solid 1px #f2f5f8;
            transition: 0.2s;

            &:last-of-type {
                border: none;
            }

            &:hover {
                background: rgb(235, 235, 235);
            }
        }
    }
}

</style>